import {useSearchParams} from "react-router-dom";
import {MapContainer, TileLayer, Marker, Popup} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {LatLngExpression} from "leaflet";
import * as L from "leaflet";
import "leaflet/dist/leaflet.js";
import "leaflet/dist/leaflet.css";

const DefaultIcon = L.icon({
  iconUrl: "/static/img/marker-icon.png",
  shadowUrl: "/static/img/marker-shadow.png"
});


export default function ParkMap({buildings}: Props) {
  const [params] = useSearchParams();
  // const buildings = data?.building.filter(b => buildingIds.includes(b.id));
  const markers = buildings?.filter(building => {
    return building.informations?.latitude && building.informations?.longitude;
  }).map((building: any) => {
    const {latitude, longitude} = building.informations;
    const url =`/dashboard/building?parc=${params.get("parc")}&building=${building.id}`;
    const popUpLinkTsx = (
      <Popup>
        <a href={url}>{building.name}</a>
        <br/>
        <span>{building.address}</span>
      </Popup>
    );
    return {position: [latitude, longitude], popup: popUpLinkTsx};
  });
  
  return (
    <>
      <div className="card" style={{zIndex: 0}}>
        <div className="card-body">
          <div className="main-content-label park-map-card">
            {gettext("Map of buildings")}
          </div>
          <div className="ht-300">
            <MapContainer style={{ height: "300px" }} center={[48.85341, 2.3488]} zoom={10}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {
                markers?.map(marker => {
                  return <Marker position={marker.position as LatLngExpression} key={Math.random()} icon={DefaultIcon}>
                    {marker.popup}
                  </Marker>;
                })
              }
            </MapContainer>
          </div>
        </div>
      </div>
    </>
  );
}


interface Props {
  buildings: any[];
}
