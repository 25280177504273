import { Route, Routes } from "react-router-dom";
import { Metrology } from "../metrology";
import { Mode } from "../common";
import { ComfortDashboard } from "../dashboards/comfort";
import SwitchSite from "@src/sites";
import CosticForm from "@src/costic";
import { SigninPage } from "@src/auth/react/pages/SigninPage";
import { ResetPasswordPage } from "@src/auth/react/pages/ResetPasswordPage";
import {ForgotPasswordPage} from "@src/auth/react/pages/ForgotPasswordPage";
import {ParcDashboard} from "@src/dashboards/parcs/parc-dashboard";

export default function Router() {
  return (
    <Routes>
      <Route path="/account">
        <Route path='signin' element={<SigninPage />} />
        <Route path='password/forgot' element={<ForgotPasswordPage />} />
        <Route path='password/reset' element={<ResetPasswordPage />} />
      </Route>
      <Route path='/home/sites/redirect' element={<SwitchSite/>} />
      <Route
        path="/comfort/metrology"
        element={<Metrology mode={Mode.COMFORT} />}
      />
      <Route path="/dashboard/parc" element={<ParcDashboard />} />
      <Route path="/comfort/dashboard" element={<ComfortDashboard />}>
        <Route path="list" element={null} />
        <Route path="table" element={null} />
        <Route path="analysis" element={null} />
      </Route>
      <Route
        path="/energy/metrology"
        element={<Metrology mode={Mode.ENERGY} />}
      />
      <Route
        path="/energy/costic/upload"
        element={<CosticForm />}
      />
    </Routes>
  );
}
