import PieChart from "@src/common/components/pie-chart";

export default function BuildingDistribution({data}: Props) {
  return (
    <>
      <PieChart data={data?.network} setShowText={(_) => null}/>
      <PieChart data={data?.dpe} setShowText={(_) => null}/>
      <PieChart data={data?.ges} setShowText={(_) => null}/>
    </>
  );
}

interface Props {
  data: Doughnut,
}

interface Doughnut {
  network: any;
  dpe: any;
  ges: any;
}