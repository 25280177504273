import {useTranslation} from "react-i18next";

function synthesesBuildings(data: any[], maxPage=5) {
  let page = 0;
  if (data.length >= maxPage) {
    $("#paginate-buildings .page-item").off("click");
    $("#paginate-buildings .page-item").on("click", function (e) {
      const go = $(this).data("go");

      if (go === "prev" && page > 0) {
        page -= 1;
      } else if (go === "next" && page < (data.length - (data.length % maxPage)) / maxPage) {
        page += 1;
      }
      window.scrollTo(0, document.body.scrollHeight);
      display();
    });
  }
  const display = () => {
    if (data.length <= maxPage) {
      $("#paginate-buildings .page-item .page-link.page-next").parent().addClass("disabled");
      $("#paginate-buildings .page-item .page-link.page-prev").parent().addClass("disabled");
    } else if (page === (data.length - (data.length % maxPage)) / maxPage) {
      $("#paginate-buildings .page-item .page-link.page-next").parent().addClass("disabled");
      $("#paginate-buildings .page-item .page-link.page-prev").parent().removeClass("disabled");
    } else if (page === 0) {
      $("#paginate-buildings .page-item .page-link.page-next").parent().removeClass("disabled");
      $("#paginate-buildings .page-item .page-link.page-prev").parent().addClass("disabled");
    } else {
      $("#paginate-buildings .page-item .page-link.page-next").parent().removeClass("disabled");
      $("#paginate-buildings .page-item .page-link.page-prev").parent().removeClass("disabled");
    }
    const components = data.slice(page * maxPage, page * maxPage + maxPage).map((item: any) => {
      const building = item.building;
      const informations = building.informations;
      const url = `/dashboard/building?parc=${building.project}&building=${building.id}`;
      const rows = `
        <tr>
          <td>
            <a href=${url}>${building.name}</a>
          </td>
          <td>
            ${informations.constructionYear}
          </td>
          <td>
            ${informations.surface}
          </td>
          <td>
            ${(item.energies.elec?.consumed > 0 ? (item.energies.elec?.consumed / 1000).toFixed(3) : 0) || "-"}
          </td>
          <td>
            ${(item.energies.gas?.consumed > 0 ? (item.energies.gas?.consumed / 1000).toFixed(3) : 0) || "-"}
          </td>
          <td>
            ${(item.energies.hot?.consumed > 0 ? (item.energies.hot?.consumed / 1000).toFixed(3) : 0) || "-"}
          </td>
          <td>
            ${(item.energies.cold?.consumed > 0 ? (item.energies.cold?.consumed / 1000).toFixed(3) : 0) || "-"}
          </td>
          <td>
            ${item.expenses ? Math.round(item.expenses) : "-"}
          </td>
        </tr>
      `;
      return rows;
    });
    
    const component = `
      <tbody>
        ${components.join("\n")}
      </tbody>
    `;
    const html = document.querySelector("#synthesesBuildings tbody");
    html.innerHTML = component;
  };
  display();
  return data;

}

type Props = {
  data: any[]
}

export default function MeasuringPoints({data}: Props) {
  const {t} = useTranslation();
  if (data.length) {
    synthesesBuildings(data);
  }
  return (
    <>
      <div className="card text-center">
        <div className="card-body">
          <h4 className="card-title pb-2">
            {t("Synthèse des bâtiments")}
          </h4>
          <div className="table-responsive text-center">
            <table className="table text-md-nowrap" id="synthesesBuildings">
              <thead>
                <tr>
                  <th className="wd-11p border-bottom-0 tx-15-f text-center">{t("Bâtiment")}</th>
                  <th className="wd-11p border-bottom-0 tx-15-f text-center">{t("Construction")}</th>
                  <th className="wd-11p border-bottom-0 tx-15-f text-center">{t("Surface")} (m²)</th>
                  <th className="wd-11p border-bottom-0 tx-15-f text-center">{t("Electricité")} (MWh)</th>
                  <th className="wd-11p border-bottom-0 tx-15-f text-center">{t("Gaz")} (MWh)</th>
                  <th className="wd-11p border-bottom-0 tx-15-f text-center">{t("Réseau de chaud")} (MWh)</th>
                  <th className="wd-11p border-bottom-0 tx-14-f text-center">{t("Réseau de froid")} (MWh)</th>
                  <th className="wd-11p border-bottom-0 tx-15-f text-center">{t("Cout total")} &euro;</th>
                </tr>
              </thead>
              <tbody>
                        
              </tbody>
            </table>
            <ul id="paginate-buildings" className="pagination m-2">
              <li data-go="prev" className="page-item disabled m-1">
                <button className="page-link page-prev"
                  id="sensors-page-prev"
                  data-go="prev">{gettext("Précédent")}</button>
              </li>
              <li data-go="next" className="page-item m-1">
                <button className="page-link page-next"
                  data-go="next" id="sensors-page-next">{gettext("Suivant")}</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}