import {useTranslation} from "react-i18next";

function getAverageAndMedian(array: any, field: any) {
  const medianKey = gettext("Médiane");
  const averageKey = gettext("Moyenne");
  if (!array.length || !field) {
    return {[medianKey]: 0, [averageKey]: 0};
  }

  const arrayLength = array.length;

  const sum = array.reduce((total: any, object: any) => {
    const value = object[field] / object.building.informations?.surface;
    return total + value;
  }, 0);
  const average = sum / arrayLength;

  const middleIndex = Math.floor(arrayLength / 2);
  let obj = array[middleIndex]
  const middleValue = obj[field] / obj.building.informations?.surface;

  let median;
  if (arrayLength % 2 === 1) {
    median = middleValue;
  } else {
    obj = array[middleIndex - 1]
    const middleValue2 = obj[field] / obj.building.informations?.surface;
    median = (middleValue + middleValue2) / 2;
  }

  return {[medianKey]: median, [averageKey]: average}
}

export const AverageFlop = (name: string, value: any, max: any) => {
  const left = Math.ceil((value / max) * 90);
  const result = left - (left % 5);
  const klass = `progress-bar wd-${result}p`;

  return `
    <li>
      <div class="d-flex justify-content-between">
        <h6>${name}</h6>
        <p class="fw-semibold mb-2">
          ${value.toFixed(2)}
          <span class="text-muted fw-normal"></span>
        </p>
      </div>
      <div class="progress ht-5">
        <div
          aria-valuemax=${100} aria-valuemin=${0}
          aria-valuenow=${result}
          class="${klass}"
          style="background-color: #563d7c"
          role="progressbar">
        </div>
      </div>
    </li>
  `;
};

export const Flop = (building: any, value: any, max: any) => {
  const newValue = value / (building.informations?.surface || 1);
  const left = Math.ceil((newValue / max) * 90);
  const result = left - (left % 5);

  const klass = `progress-bar wd-${result}p bg-primary`;
  return `
    <li>
      <div class="d-flex justify-content-between">
        <h6>${building.name}</h6>
        <p class="fw-semibold mb-2">
          ${newValue.toFixed(2)}
          <span class="text-muted fw-normal"></span>
        </p>
      </div>
      <div class="progress ht-5">
        <div aria-valuemax=${100} aria-valuemin=${0}
          aria-valuenow=${result}
          class="${klass}"
          role="progressbar">
        </div>
      </div>
    </li>
    `;
};

function loadDataFlops(data: any, elems: any, field: any, maxPerPage: string) {
  const element = document.querySelector(elems[field]);
  if (element) {
    element.innerHTML = "";
  }
  const _data = [].concat(data).filter(d => !!d.building.informations.surface);
  _data.sort((a, b) => {
    const surfaceA = a.building.informations?.surface || 1;
    const surfaceB = b.building.informations?.surface || 1;
    return Number(b[field] / surfaceB) - Number(a[field] / surfaceA);
  });
  _data.slice(0, +maxPerPage).forEach((synthesis) => {
    const htmlElement = document.querySelector(elems[field]);
    const max = _data[0][field] / _data[0].building.informations.surface;
    const component = Flop(synthesis.building, synthesis[field], max);
    htmlElement.innerHTML += component;
  });

  const elements = getAverageAndMedian(_data.slice(0, +maxPerPage), field);
  Object.entries(elements).forEach(([key, value]) => {
    const htmlElement = document.querySelector(elems[field]);
    const max = _data[0][field] / _data[0].building.informations.surface;
    const component = AverageFlop(key, value, max);
    htmlElement.innerHTML += component;
  });
}

const hasOnShowMoreEvent = {consumed: false, emissions: false, expenses: false};

function flops(data: any[]) {
  if (!data.length) {
    return;
  }
  const elems = {
    consumed: "#idEnergyIntensiveBuildings",
    expenses: "#idCostIntensiveBuildings",
    emissions: "#idCo2IntensiveBuildings"
  };
  type Field = keyof typeof elems
  ["consumed", "expenses", "emissions"].forEach((field: Field) => {
    const buttonElement = document.querySelector(elems[field]).nextElementSibling;
    if (!hasOnShowMoreEvent[field]) {
      // Need this condition, to avoid adding this event twice.
      buttonElement.addEventListener("click", () => {
        const maxPerPage = (+(buttonElement as HTMLDivElement).dataset.maxPerPage + 7).toString();
        loadDataFlops(data, elems, field, maxPerPage);

        if (+maxPerPage >= data.length) {
          buttonElement.textContent = gettext("Voir moins");
          (buttonElement as HTMLDivElement).dataset.maxPerPage = "0";
          return;
        }
        buttonElement.textContent = gettext("Voir plus");
        (buttonElement as HTMLDivElement).dataset.maxPerPage = maxPerPage;
      });
      hasOnShowMoreEvent[field] = true;
    }
    loadDataFlops(data, elems, field, "7");
  });
}

type Props = {
  data: any
}

export default function BuildingClassification({data}: Props) {
  const {t} = useTranslation();
  flops(data);

  return (
    <>
      <div className="col-4">
        <div className="card">
          <div className="card-header bg-transparent pd-b-0 pd-t-20 bd-b-0 text-center">
            <div className="d-flex justify-content-center">
              <h4 className="card-title mg-b-10">
                {t("Bâtiments énergivores")}
                <br />
                <small className="text-muted">
                  {t("kWh EF/m²/an")}
                </small>
              </h4>
            </div>
          </div>
          <div className="card-body">
            <ul className="sales-session mb-0" id="idEnergyIntensiveBuildings"></ul>
            <button className="page-link m-auto" data-max-per-page="7">{t("Show more")}</button>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="card">
          <div className="card-header bg-transparent pd-b-0 pd-t-20 bd-b-0 text-center">
            <div className="d-flex justify-content-center">
              <h4 className="card-title mg-b-10">
                {t("Bâtiments polluants")}
                <br />
                <small className="text-muted">
                  {t("kg CO2/m²/an")}
                </small>
              </h4>
            </div>
          </div>
          <div className="card-body">
            <ul className="sales-session mb-0" id="idCo2IntensiveBuildings"></ul>
            <button className="page-link m-auto" data-max-per-page="7">{t("Show more")}</button>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="card">
          <div className="card-header bg-transparent pd-b-0 pd-t-20 bd-b-0 text-center">
            <div className="d-flex justify-content-center">
              <h4 className="card-title mg-b-10">
                {t("Bâtiments couteux")}
                <br />
                <small className="text-muted">
                  {t("€/m²/an")}
                </small>
              </h4>
            </div>
          </div>
          <div className="card-body">
            <ul className="sales-session mb-0" id="idCostIntensiveBuildings"></ul>
            <button className="page-link m-auto" data-max-per-page="7">{t("Show more")}</button>
          </div>
        </div>
      </div>
    </>
  );
}