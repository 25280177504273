import Select from "react-select";
import {removeDuplication} from "@src/common/utils";
import {useTranslation} from "react-i18next";
import {useState} from "react";

interface SelectOption {
  readonly label: string,
  readonly value: string
}


export default function DashboardParkFilter({filters, synthesisData, setFilteredSynthesisData, parsedData, setFilteredParsedData}: Props) {
  const {t} = useTranslation();
  const [selectedRegion, setSelectedRegion] = useState<any[]>([]);
  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const [selectedConstructionYear, setSelectedConstructionYear] = useState([]);
  const [selectedMainActivities, setSelectedMainActivities] = useState([]);

  const regionsOptions = filters?.area.filter(v => !!v).map(region => {
    const label =  t(capitalize(region, "-"));
    const option: SelectOption =  {label, value: region};
    return option;
  });

  const fixedConstructionYearOptions: readonly SelectOption[] =  [
    {"label": t("Before 1974"), "value": "0-1974"},
    {"label": t("Between 1974 and 1988"), "value": "1974-1988"},
    {"label": t("Between 1988 and 2000"), "value": "1988-2000"},
    {"label": t("Between 2000 and 2005"), "value": "2000-2005"},
    {"label": t("Between 2005 and 2012"), "value": "2005-2012"},
    {"label": t("After 2012"), "value": "2012-9999"}
  ];

  const mainActivities = filters?.main_activities.map(item => {
    let translate = t(item);
    if (!item) {
      translate = t("Not referenced");
      item = "0";
    }
    const option: SelectOption = {label: translate, value: item};
    return option;
  });
  const mainActivitiesOptions = removeDuplication(mainActivities ?? []);
  

  const networkOptions = Object.entries(filters?.network ?? {}).filter(([_, value]) => value > 0).map(([key]) => {
    let label: string;
    let value: string;
    switch (key) {
    case "urban_network":
      label = t("Heating network");
      value = "urban_network";
      break;
    case "cooling_network":
      label = t("Cooling network");
      value = "cooling_network";
      break;
    case "electricity_network":
      label = t("Electricity");
      value = "electricity_network";
      break;
    case "gas_network":
      label = t("Gas");
      value = "gas_network";
      break;
    case "water_network":
      label = t("Water");
      value = "water_network";
      break;
    }
    const option: SelectOption = {label, value};
    return option;
  });

  function capitalize(value: string, separator = " ") {
    if (value === undefined) {
      return "";
    }
    return value
      .split(separator)
      .map(v => v.charAt(0).toUpperCase() + v.substring(1).toLowerCase())
      .join(separator);
  }

  function filterByBuilding(data: any, property: string, options: any[]) {
    // const properties = ["city", "network", "mainActivity", "constructionYear"];
    return data.filter(
      (value: any) => {
        if (property == "city") {
          return options.includes(value.building.informations?.city?.toLowerCase());
        } else if (property == "network") {
          return options.some(option => value.building.configuration[option]);
        } else if (property == "constructionYear") {
          return options.some(option => {
            const construction = value.building.informations?.constructionYear;
            const years = option.split("-").map((d: any) => +d);
            return construction >= years[0] && construction <= years[1];
          });
        } else if (property == "mainActivity") {
          return options.includes(value.building.informations?.mainActivity?.toLowerCase());
        }
      }
    );
  }

  return (
    <>
      <div className="filter-region col-12 park-filter-group">
        <h6>{t("Region")}</h6>
        <Select
          id="filterRegion"
          options={regionsOptions}
          isMulti={true}
          closeMenuOnSelect={false}
          placeholder={t("Select a region")}
          value={selectedRegion}
          onChange={(options) => {
            if (options.length == 0) {
              setSelectedRegion([]);
              setFilteredSynthesisData(synthesisData);
              return;
            }
            const cities = options.map(o => o.value.toLowerCase());
            const newData = filterByBuilding(synthesisData, "city", cities);
            setSelectedRegion(options as any);
            setFilteredSynthesisData(newData);
          }}
        />
      </div>
      <div className="filter-network col-12 mt-3 park-filter-group">
        <h6>{t("Network")}</h6>
        <Select
          id="filterNetwork"
          options={networkOptions}
          isMulti={true}
          closeMenuOnSelect={false}
          value={selectedNetwork}
          onChange={(options) => {
            if (options.length == 0) {
              setSelectedNetwork([]);
              setFilteredSynthesisData(synthesisData);
              return;
            }
            const networks = options.map(o => o.value.toLowerCase());
            const newData = filterByBuilding(synthesisData, "network", networks);
            setSelectedNetwork(options as any);
            setFilteredSynthesisData(newData);
          }}
          placeholder={t("Select a network")}
        />
      </div>
      <div className="filter-build col-12 mt-3 park-filter-group">
        <h6>{t("Construction year")}</h6>
        <Select
          id="filterBuild"
          options={fixedConstructionYearOptions}
          placeholder={t("Select a year")}
          value={selectedConstructionYear}
          onChange={(options) => {
            if (options.length == 0) {
              setSelectedConstructionYear([]);
              setFilteredSynthesisData(synthesisData);
              return;
            }
            const years = options.map(o => o.value.toLowerCase());
            const newData = filterByBuilding(synthesisData, "constructionYear", years);
            setSelectedConstructionYear(options as any);
            setFilteredSynthesisData(newData);
          }}
          closeMenuOnSelect={false}
          isMulti={true}
        />
      </div>
      <div className="filter-activity col-12 mt-3 park-filter-group">
        <h6>{t("Main activity")}</h6>
        <Select 
          id="filterActivity" 
          options={mainActivitiesOptions} 
          isMulti={true}
          value={selectedMainActivities}
          onChange={(options) => {
            if (options.length == 0) {
              setSelectedMainActivities([]);
              setFilteredSynthesisData(synthesisData);
              return;
            }
            const years = options.map(o => o.value.toLowerCase());
            const newData = filterByBuilding(synthesisData, "mainActivity", years);
            setSelectedMainActivities(options as any);
            setFilteredSynthesisData(newData);
          }}
          placeholder={t("Select an activity")}
          closeMenuOnSelect={false}
        />
      </div>
    </>
  );
}

interface Props {
  filters: Filters;
  synthesisData: any;
  parsedData: any;
  setFilteredParsedData: (data: any) => void;
  setFilteredSynthesisData: (data: any) => void;
}

interface Filters {
  building: any;
  network: {[key:string]: number};
  main_activities: any[];
  area: any[]
}