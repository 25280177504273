export default function ParkCard({detail}: Props) {
  return (
    <div className="card card-dashboard-eight pb-2 park-card">
      <h6 className="card-title text-center">{gettext("Park Details")}</h6>
      <div className="list-group border-top-0">
        <div className="list-group-item border-top-0" id="br-t-0">
          <p>{gettext("Total surface building cluster")}</p>
          <span className="total_surface">{detail?.total_surface ?? "-"}</span>
        </div>
        <div className="list-group-item mb-0">
          <p>{gettext("Number of buildings")}</p>
          <span className="number_of_buildings">{detail?.number_of_buildings ?? "-"}</span>
        </div>
        <div className="list-group-item mb-0">
          <p>{gettext("Type of activities")}</p>
          <span className="main_activities">{detail?.main_activities ?? "-"}</span>
        </div>
        <div className="list-group-item mb-0">
          <p>{gettext("Type of sensors")}</p>
          <span className="sensor_type">{detail?.sensor_type ?? "-"}</span>
        </div>
        <div className="list-group-item border-bottom-0 mb-0">
          <p>{gettext("Number of capteurs")}</p>
          <span className="number_of_sensors">{detail?.number_of_sensors ?? "-"}</span>
        </div>
      </div>
    </div>
  );
}

interface Props {
  detail: ParkDetail
}

interface ParkDetail {
  total_surface: string;
  number_of_buildings: number;
  main_activities: string;
  sensor_type: string;
  number_of_sensors: number;
}

