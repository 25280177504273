import {DashboardDomainModel} from "@src/dashboards/core/models/buildings";

export class ParkFilter {
  constructor(
    public cities: string[] = [],
    public networks: string[] = [],
    public constructionYear: string[] = [],
    public mainActivities: string[] = [],
  ) {}

  isMatchingCriteria(building: DashboardDomainModel.Building): boolean {
    const matchesRegions = this.cities.length === 0 || this.cities.includes(building.informations.city);
    const matchesNetworks = this.networks.length === 0 || !!this.networks.find(
      network => building.configuration[network as keyof DashboardDomainModel.BuildingConfiguration]
    );
    const matchesMainActivities = this.mainActivities.length === 0 || this.mainActivities.includes(building.informations.mainActivity);
    const matchesConstructionYear = this.constructionYear.length === 0 || this.constructionYear.includes(building.informations.constructionYear);

    return matchesRegions && matchesMainActivities && matchesConstructionYear && matchesNetworks;
  }


  update(values: string[], type: string) {
    const key = `${type}s` as "cities" | "networks" | "constructionYear" | "mainActivities";
    this[key] = [...new Set(values)];
  }
}
