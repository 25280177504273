import {useTranslation} from "react-i18next";

export default function TotalEconomy(economyValue: any) {
  const {t} = useTranslation();
  return(
    <div className="card overflow-hidden sales-card bg-success-gradient">
      <div className="ps-3 pt-3 pe-3 pb-2 pt-0 total-headers"
        style={{paddingLeft: "1rem!important", paddingRight: "1rem!important"}}>
        <div>
          <h6 className="mb-3 tx-16 text-white">
            {t("Saving")}
          </h6>
        </div>
        <div className="pb-0 mt-0">
          <div className="d-flex">
            <div id="parc-total-economy">
              <h6 className="no-data-btn text-white tx-22">
                { t("No data available")}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}