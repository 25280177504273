import {useEffect, useRef, useState} from "react";
import BuildingClassification from "./building-classification";
import BuildingDistribution from "./building-distribution";
import DashboardParkFilter from "./filters/park-filter";
import "./index.scss";
import TotalIndicators from "./indicators/total-indicators";
import MeasuringPoints from "./measuring-points";
import ParkCard from "./park-card";
import ParkMap from "./park-map";
import axios from "axios";
import {useSearchParams} from "react-router-dom";
import Breadcrumb from "@src/layout/breadcrumb";
import Select from "react-select";
import {Header} from "@src/layout/Header";
import {SideBar} from "@src/layout/SideBar";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import _ from "lodash";
import {ParkFilter} from "@src/dashboards/core/filters/buildings";

interface SelectOption {
  readonly label: string,
  readonly value: string
}

export function ParcDashboard() {

  const [params, setParams] = useSearchParams();
  const [project, setProject] = useState<any>();
  const [data, setData] = useState<any>();
  const [parsedData, setParsedData] = useState<any>({});
  const [filteredParsedData, setFilteredParsedData] = useState<any>({});
  const [synthesisData, setSynthesisData] = useState<any>([]);
  const [filteredSynthesisData, setFilteredSynthesisData] = useState<any>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<any[]>([]);
  const parkFilter = useRef(new ParkFilter());

  const parcId = params.get("parc");
  const date_from = params.get("date_from");
  const date_to = params.get("date_to");

  if (!date_from && !date_to) {
    const period = moment();
    setParams({
      parc: parcId,
      date_from: period.clone().subtract(1, "years").format("YYYY-MM-DD"),
      date_to: period.clone().format("YYYY-MM-DD")
    });
  }

  useEffect(() => {
    axios(`/api/v1/dashboard_park/${parcId}`).then((res) => {
      const data: ParkResponse = res.data;
      setProject(data?.global?.name);
      setData(data);
      const result: ParsedData = parseData(data.global, data.equipments);
      console.log("[DEBUG] Parsed Data Networks: ", result.doughnut.network);
      setParsedData(result);
      setFilteredParsedData(result);
    });
    axios(`/energy/api/synthesis?parc=${parcId}&simplified=1&${params}`).then((res) => {
      setSynthesisData(Object.values(res.data));
      const synthesisFilteredData = filterByBuilding(res.data);
      setFilteredSynthesisData(synthesisFilteredData);
    }).finally(() => {
      console.log("Fetch finished");
    });
  }, [date_from]);


  const yearOptions: SelectOption[] = [{
    "label": gettext("12 rolling months"), "value": "default"
  }];
  for (let i = new Date().getFullYear(); i >= 2010; i--) {
    yearOptions.push({
      "label": `${i}`, "value": `${i}`
    });
  }

  function filterByBuilding(data: any) {
    if (selectedBuilding.length == 0) {
      return data;
    }

    return data.filter(
      (value: any) => selectedBuilding.find(s => s.id == value.building.id)
    );
  }
  
  function isDigit(str: string) {
    return /^\d+$/.test(str);
  }

  function capitalize(value: string, separator = " ") {
    if (value === undefined || value === null) {
      return "";
    }
    return value
      .split(separator)
      .map(v => v.charAt(0).toUpperCase() + v.substring(1).toLowerCase())
      .join(separator);
  }

  function parseData(data: any, equipments: string[]): ParsedData {
    const dataInformationBuilding: { [key: string]: any }[] = [];
    const dataConfigurationBuilding: { [key: string]: any }[] = [];
    const filterInformationMap = {
      "region": "city",
      "activity": "mainActivity",
      "build": "constructionYear"
    };
    const filterConfigurationMap = {
      "network": "configuration"
    };
    const filters: { [key: string]: any } = {};
    let selectedSurface = 0;
    let selectedSensors = 0;
    const newSelectedBuilding: any[] = [];
    let hasValues = false;

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((_, key) => {
      if (key === "date_from" || key === "date_to") {
        return;
      }
      if (key !== "parc") {
        const paramValues = JSON.parse(urlParams.get(key));
        const infoField = filterInformationMap[key as keyof typeof filterInformationMap];
        const confField = filterConfigurationMap[key as keyof typeof filterConfigurationMap];
        if (infoField) {
          filters[infoField] = paramValues.map((v: string) => isDigit(v) ? +v : v);
        }
        if (confField) {
          filters[confField] = paramValues.map((v: string) => isDigit(v) ? +v : v);
        }
        hasValues = !!(infoField || confField);
      }
    });

    data?.building.forEach(function (building: any) {
      if (!building.configuration?.active) {
        return;
      }
      const information = building["informations"];
      const configuration = building["configuration"];

      if (!hasValues) {
        setSelectedBuilding(oldState => [...oldState, building]);
        dataInformationBuilding.push(information);
        dataConfigurationBuilding.push(configuration);
        selectedSurface += information.surface || 0;
        selectedSensors += equipments.filter(d => d === building.name).length;
        return;
      }
      let hasInformation = false;
      let hasConfiguration = false;
      for (const property in filters) {
        if (property === "constructionYear") {
          for (const value of filters[property]) {
            const years = value.split("-").map((d: string) => +d);
            if (years.length >= 0) {
              const buildingYear = information[property];
              if (buildingYear >= years[0] && buildingYear <= years[1]) {
                hasInformation = true;
                break;
              }
            }
          }
        }
        else {
          hasInformation = filters[property].includes(information[property]);
        }
        hasConfiguration = filters[property].every((key: any) => configuration[key]);
        if (!hasInformation && !hasConfiguration) break;
      }
      if (hasInformation || hasConfiguration) {
        newSelectedBuilding.push(building.id);
        dataInformationBuilding.push(information);
        dataConfigurationBuilding.push(configuration);
        selectedSurface += information.surface || 0;
        selectedSensors += equipments.filter(d => d === building.name).length;
      }
      setSelectedBuilding(newSelectedBuilding);
    });

    const tmpSensorsComfort = [...new Set(dataInformationBuilding.map(item => item.comfort).filter(item => item !== ""))];
    const tmpSensorEnergy = [...new Set(dataInformationBuilding.map(item => item.energy).filter(item => item !== ""))];
    const resultTypeSensors = [];
    if (tmpSensorEnergy.length && tmpSensorEnergy[0] !== 0) {
      resultTypeSensors.push(gettext("Energy"));
    }
    if (tmpSensorsComfort.length && tmpSensorsComfort[0] !== 0) {
      resultTypeSensors.push(gettext("Comfort"));
    }

    const network = {
      "electricity_network": dataConfigurationBuilding.filter((obj) => obj.electricity_network).length,
      "gas_network": dataConfigurationBuilding.filter((obj) => obj.gas_network).length,
      "urban_network": dataConfigurationBuilding.filter((obj) => obj.urban_network).length,
      "cooling_network": dataConfigurationBuilding.filter((obj) => obj.cooling_network).length,
      "water_network": dataConfigurationBuilding.filter((obj) => obj.water_network).length
    };
    const gesDoughnutValues = generateDoughnutValueFromInformation(dataInformationBuilding, "gesLabel");
    const dpeDoughnutValues = generateDoughnutValueFromInformation(dataInformationBuilding, "dpeLabel");

    return {
      park_detail: {
        "total_surface": selectedSurface.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " m²",
        "number_of_buildings": dataInformationBuilding.length,
        "main_activities": [...new Set(dataInformationBuilding.filter(item => isNaN(item.mainActivity)).map(item => gettext(item.mainActivity)))].join(", "),
        "sensor_type": resultTypeSensors.join(", "),
        "number_of_sensors": selectedSensors
      },
      filters: {
        "building": dataInformationBuilding,
        "network": network,
        "main_activities": [...new Set(dataInformationBuilding.map(item => item.mainActivity))],
        "area": [...new Set(dataInformationBuilding.map(item => capitalize(item.city)))],
      },
      doughnut: {
        network: [
          {name: gettext("Electricity"), value: network.electricity_network, fill: "#ffe45e", unit: ""},
          {name: gettext("Gas"), value: network.gas_network, fill: "#cdc1ff", unit: ""},
          {name: gettext("Heating network"), value: network.urban_network, fill: "#e3635d", unit: ""},
          {name: gettext("Cooling network"), value: network.cooling_network, fill: "#5aa9e6", unit: ""},
          {name: gettext("Water"), value: network.water_network, fill: "#90f3ff", unit: ""}
        ],
        dpe: [
          {name: "A", value: dpeDoughnutValues.A, fill: "#60d394", unit: ""},
          {name: "B", value: dpeDoughnutValues.B, fill: "#ffd97d", unit: ""},
          {name: "C", value: dpeDoughnutValues.C, fill: "#ff9b85", unit: ""},
          {name: "D", value: dpeDoughnutValues.D, fill: "#ee6055", unit: ""},
        ],
        ges: [
          {name: "A", value: gesDoughnutValues.A, fill: "#60d394", unit: ""},
          {name: "B", value: gesDoughnutValues.B, fill: "#ffd97d", unit: ""},
          {name: "C", value: gesDoughnutValues.C, fill: "#ff9b85", unit: ""},
          {name: "D", value: gesDoughnutValues.D, fill: "#ee6055", unit: ""},
        ]
      },
    };
  }

  function generateDoughnutValueFromInformation(data: {[key: string]: any}[], field: "dpeLabel" | "gesLabel") {
    const countOfLabel = {A: 0, B: 0, C: 0, D: 0};
    for (const item of data) {
      const itemValue = item[field];
      if (itemValue in countOfLabel) {
        countOfLabel[itemValue as keyof typeof countOfLabel]++;
      }
    }
    return countOfLabel;
  }
  
  function generateDoughnutValueFromConfiguration(data: {[key: string]: any}[], fields: string[]) {
    type Counter = {[key: string]: number};
    const countOfField = fields.reduce((accumulator: Counter, currentValue: string) => {
      accumulator[currentValue] = 0;
      return accumulator;
    }, {});
    for (const item of data) {
      fields.forEach(field => field in item ? countOfField[field]++: null);
    }
    return countOfField;
  }
  
  const indicatorsValues = {
    consumption: 0,
    expenditure: 0,
    emissions: 0,
    economy: 0,
  };

  filteredSynthesisData?.map((building: any) => {
    indicatorsValues.consumption += building.consumed;
    indicatorsValues.expenditure += building.expenses;
    indicatorsValues.emissions += building.emissions; 
  });
  indicatorsValues.consumption = Math.round(indicatorsValues.consumption / 1000);
  indicatorsValues.emissions = Math.round(indicatorsValues.emissions / 1000);
  indicatorsValues.expenditure = Math.round(indicatorsValues.expenditure);

  useEffect(() => {
    if (data && filteredSynthesisData) {
      const copyData = _.cloneDeep(data);
      const buildings = filteredSynthesisData.map((d: any) => d.building);
      copyData.global.building = _.cloneDeep(buildings);
      const result = parseData(copyData["global"], copyData["equipments"]);
      setFilteredParsedData(result);
    }
  }, [filteredSynthesisData]);

  const {t} = useTranslation();

  return (
    <div id="container-projects">
      <div className="row row-sm">
        <div className="col"></div>
        <div className="col"></div>
        <div className="col"></div>
        <div className="col">
          <Select
            id="filterBreadcrumb"
            options={yearOptions}
            placeholder={t("Select a year")}
            onChange={(option) => {
              if (option.value === "default") {
                const period = moment();
                params.set("date_from", period.clone().subtract(1, "years").format("YYYY-MM-DD"));
                params.set("date_to", period.clone().format("YYYY-MM-DD"));
              } else {
                const period = moment().set("years", +option.value);
                setParams((oldState) => {
                  return {
                    parc: parcId,
                    date_from: period.clone().month(0).date(1).format("YYYY-MM-DD"),
                    date_to: period.clone().month(11).date(31).format("YYYY-MM-DD")
                  };
                });
              }
            }}
          />
        </div>
      </div>
      <br/>
      <div className="row row-sm">
        <div className="col">
          <TotalIndicators {...indicatorsValues}/>
        </div>
      </div>
      <div className="row row-sm">
        <div className="col col-md-6 park-card">
          <ParkCard detail={filteredParsedData?.park_detail}/>
        </div>
        <div className="col col-md-6" style={{marginBottom: "20px"}}>
          <div className="card h-100"
            style={{maxHeight: "375px", minHeight: "375px"}}>
            <div className="card-body">
              <div className="main-content-label text-center filter-title">
                {t("Filters")}
              </div>
              <div className="content-filter row">
                <DashboardParkFilter
                  filters={parsedData?.filters}
                  synthesisData={synthesisData}
                  setFilteredSynthesisData={setFilteredSynthesisData}
                  parsedData={parsedData}
                  setFilteredParsedData={setFilteredParsedData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-sm">
        <div className="col-5" id="map" data-map="{map}">
          <ParkMap buildings={filteredSynthesisData?.map((d: any) => d.building)} />
        </div>
        <div className="col-7">
          <div className="card mg-b-20 mg-md-b-0">
            <div className="card-body">
              <div className="card-header pb-0">
                <h3 className="card-title mb-2"
                  style={{textAlign: "center"}}>
                  {t("Répartitions des bâtiments")}
                </h3>
              </div>
              <div className="content-pie-energies ht-300" id="buildingsPieCharts">
                <BuildingDistribution data={filteredParsedData.doughnut} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-sm">
        <BuildingClassification data={filteredSynthesisData}/>
      </div>
      <div className="row row-sm">
        <div className="col">
          <MeasuringPoints data={filteredSynthesisData ?? []}/>
        </div>
      </div>
    </div>
  );
}

interface ParkResponse {
  surface: number;
  logo_url: string;
  equipment: number;
  equipments: string[];
  total_building: number;
  building_ids: number[];
  monaf: any;
  global: GlobalField;
}

interface GlobalField {
  id: number;
  name: string;
  building: any[]
}

interface ParsedData {
  filters: Filters;
  park_detail: ParkDetail;
  doughnut:Doughnut;
}

interface Filters {
  building: any;
  network: {[key:string]: number};
  main_activities: any[];
  area: any[]
}

interface ParkDetail {
  total_surface: string;
  number_of_buildings: number;
  main_activities: string;
  sensor_type: string;
  number_of_sensors: number;
}

interface Doughnut {
  network: any;
  dpe: any;
  ges: any;
}
