export default function TotalConsumption(consumptionValue: any) {
  return (
    <div className="card overflow-hidden sales-card bg-warning-gradient">
      <div className="ps-3 pt-3 pe-3 pb-2 pt-0 total-headers">
        <div className="">
          <h6 className="mb-3 tx-16 text-white">
            {gettext("Consommation totale")}
          </h6>
        </div>
        <div className="pb-0 mt-0">
          <div className="d-flex">
            <div className="" id="parc-total-consumption">
              <h6 className="no-data-btn text-white tx-22">{consumptionValue.value} MWh</h6>
            </div>
          </div>
        </div>
      </div>
      {/* <span id="compositeline" className="pt-1">
            5,9,5,6,4,12,18,14,10,15,12,5,8,5,12,5,12,10,16,12
        </span> */}
    </div>
  );
}