import TotalConsumption from "./total-consumption";
import TotalCo2Emission from "./total_co2_emissions";
import TotalEconomy from "./total_economy";
import TotalExpenditure from "./total_expenditure";

function thousandSpacing(x: number) {
  // only purpose of this function is to convert :
  // "1234567 €" to "1 234 567 €" for example
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export default function(indicatorsValues: any) {
  return (
    <div className="row row-sm">
      <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
        <TotalConsumption value={thousandSpacing(indicatorsValues.consumption)}/>
      </div>
      <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
        <TotalExpenditure value={thousandSpacing(indicatorsValues.expenditure)}/>
      </div>
      <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
        <TotalCo2Emission value={thousandSpacing(indicatorsValues.emissions)}/>
      </div>
      <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
        <TotalEconomy value={thousandSpacing(indicatorsValues.economy)}/>
      </div>
    </div>
  );
}